import React from 'react';
import Helmet from 'react-helmet';
import Post from '../components/Post';
import Sidebar from '../components/Sidebar';
import {graphql} from 'gatsby';
import LinksFooter from '../components/LinksFooter';
import Layout from '../components/Layout'

class IndexRoute extends React.Component {
    render() {
        const items = [];
        const {title, subtitle, copyright, author} = this.props.data.site.siteMetadata;
        const posts = this.props.data.allMarkdownRemark.edges;
        posts.forEach((post) => {
            items.push(<Post data={post} key={post.node.fields.slug}/>);
        });

        return (
            <Layout>
                <div>
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={subtitle}/>
                    </Helmet>
                    <Sidebar {...this.props} />
                    <div className="content">
                        <div className="content__inner">
                            {items}
                            <LinksFooter data={author}/>
                            <p className="footer__copyright">
                                {copyright}<br/>
                                <a href="//cosee.biz/impressum/" rel="noopener" target="_blank">
                                    Impressum, Kontakt, Datenschutzschutzerklärung
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default IndexRoute;

export const pageQuery = graphql`
query IndexQuery {
  site {
    siteMetadata {
      title
      subtitle
      copyright
      menu {
        label
        path
      }
      author {
          name
          email
          twitter
          facebook
          meetup
          xing
          instagram
      }
    }
  }
  allMarkdownRemark(limit: 1000, filter: {frontmatter: {layout: {eq: "post"}, draft: {ne: true}}}, sort: {order: DESC, fields: [frontmatter___date]}) {
    edges {
      node {
      timeToRead
        fields {
          slug
          categorySlug
          tagSlugs
        }
        frontmatter {
          title
          date(formatString: "YYYY-MM-DD")
          category
          description
          tags
          image {
            childImageSharp {
                resize(quality: 100, width: 800) {
                    src
                    }
                }
            }
          creators {
            bio
            id
            name
            twitter
            avatar {
              childImageSharp {
                resize(quality: 100, width: 800) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

import React from 'react';
import { Link } from 'gatsby';
import moment from 'moment';
import './style.scss';

class Post extends React.Component {
    render() {
        const {title, date, category, description} = this.props.data.node.frontmatter;
        const {slug, categorySlug} = this.props.data.node.fields;
        const image = this.props.data.node.frontmatter.image.childImageSharp.resize.src;
        const timeToRead = this.props.data.node.timeToRead;
        const tagSlug = this.props.data.node.fields.tagSlugs;

        const tagsBlock = (
            <ul className="post__tags-list">
                <li className="post__tags-list-item">
                    Tags:
                </li>
                {tagSlug && tagSlug.map((tag, i) => (
                    <li className="post__tags-list-item" key={tag}>
                        <Link to={tag} className="post__tags-list-item-link">
                            #{this.props.data.node.frontmatter.tags[i]}
                        </Link>
                    </li>
                ))}
            </ul>
        );

        return (
            <div className="post">
                <div className="post__image-container">
                    <Link to={slug}>
                    <img
                        src={image}
                        alt={title}
                    />
                        </Link>
                    <div className="post__overlay">
                        <div className="post__meta">
                            <time className="post__meta-time" dateTime={moment(date).format('DD.MM.YYYY')}>
                                {moment(date).format('DD.MM.YYYY')}
                            </time>
                            <span className="post__meta-divider"/>
                            <Link to={categorySlug} className="post__meta-category-link">
                                {category}
                            </Link>
                            <span className="post__meta-readingtime">{timeToRead} min</span>
                        </div>
                    </div>
                </div>
                <h2 className="post__title">
                    <Link className="post__title-link" to={slug}>
                        {title}
                    </Link>
                </h2>
                <p className="post__description">{description}</p>

                <div className="post__tags">
                    {tagsBlock}
                </div>

                <Link className="post__readmore" to={slug}>Weiterlesen</Link>

            </div>
        );
    }
}

export default Post;
